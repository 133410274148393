import api from "./index"

/**
 * Find user by id
 *
 * @param id
 * @return {*}
 */
export const findUserByIdRequest = id => {
  return api.request(`admin/users/${id}`).get()
}

/** 
 * change user status to visited
 *
 * @param id
 * @return {*}
 */
export const userChangeVisitedStatus = id => {
  return api.request(`admin/users/${id}/changeStatus`).put()
}

/**
 * Get user Roles
 *
 * @return {*}
 */
export const getUsersRules = () => {
  return api.request(`admin/rules`).get()
}

/**
 * change role
 *
 * @param id
 * @param role
 * @return {*}
 */
export const userChangesRole = (id, role) => {
  return api
    .request(`admin/users/${id}/change_role`)
    .withBody({
      role: role
    })
    .put()
}

/**
 * ban user
 *
 * @param id
 * @param reason_id
 * @param reason_description
 * @return {*}
 */
export const userBanned = (id, reason_id, reason_description) => {
  return api
    .request(`admin/users/${id}/banned`)
    .withBody({
      reason_id: reason_id,
      reason_description: reason_description
    })
    .post()
}

/**
 * unban user
 *
 * @param id
 * @return {*}
 */
export const userUnbanned = id => {
  return api
    .request(`admin/users/${id}/unblock`)
    .withBody()
    .post()
}

/**
 *  user reports
 *
 * @param id
 * @param params
 * @return {*}
 */
export const userReportsRequest = (id, params) => {
  return api
    .request(`admin/users/${id}/reported`)
    .withParams(params)
    .get()
}
