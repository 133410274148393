import { render, staticRenderFns } from "./BlockUserModal.vue?vue&type=template&id=1b48b396"
import script from "./BlockUserModal.vue?vue&type=script&lang=js"
export * from "./BlockUserModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports